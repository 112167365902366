import {
    APP_INITIALIZER,
    EnvironmentProviders,
    importProvidersFrom,
    inject,
    Provider,
} from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { StatehandlerProcessorService, StatehandlerProcessorServiceImpl } from './statehandler-processor.service';
import { StatehandlerService, StatehandlerServiceImpl } from './statehandler.service';
import { StorageService } from './storage.service';
import { environment } from 'environments/environment';


const authProdConfig: AuthConfig = {
    scope: 'openid profile email offline_access',
    responseType: 'code',
    oidc: true,
    clientId: environment.clientID,
    issuer: `${environment.keycloakURL}/auth/realms/${environment.realmName}`,
    redirectUri: `https://${window.location.hostname}/dealer-retail-outlets`,
    postLogoutRedirectUri: `https://${window.location.hostname}/sign-out`,
    requireHttps: true,
    sessionChecksEnabled: true,
};
const stateHandlerFn = (stateHandler: StatehandlerService) => {
    return () => {
        return stateHandler.initStateHandler();
    };
};

export const provideKeyCloak = (): Array<Provider | EnvironmentProviders> => {
    return [
        importProvidersFrom(
            OAuthModule.forRoot({
                resourceServer: {
                    sendAccessToken: true,
                },
            })
        ),
        {
            provide: AuthConfig,
            useValue: authProdConfig,
        },
        {
            provide: StatehandlerProcessorService,
            useClass: StatehandlerProcessorServiceImpl,
        },
        {
            provide: StatehandlerService,
            useClass: StatehandlerServiceImpl,
        },
        {
            provide: OAuthStorage,
            useClass: StorageService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: stateHandlerFn,
            multi: true,
            deps: [StatehandlerService],
        },
    ];
};
