/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

const data: FuseNavigationItem[] = [
   
    {
        id   : 'retail-outlets',
        title: 'Retail Outlets',
        type : 'basic',
        icon : 'heroicons_outline:building-office',
        link : '/dealer-retail-outlets'
    },
    // {
    //     id   : 'dashboard',
    //     title: 'Dashboard',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/dealer-dashboard'
    // },
    {
        id   : 'dealer-report',
        title: 'RO Report',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dealer-report'
    },
    // {
    //     id   : 'territory-report',
    //     title: 'Territory Report',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/territory-report'
    // },
    {
        id   : 'du-transactions',
        title: 'Transactions',
        type : 'basic',
        icon : 'double_arrow',
        link : '/dealer-du-transactions'
    },
    {
        id   : 'foirreports',
        title: 'Sales',
        type : 'basic',
        icon : 'dashboard',
        link : '/dealer-sales'
    },
    {
        id   : 'decantation',
        title: 'Decantation',
        type : 'basic',
        icon : 'dashboard',
        link : '/decantation'
    },
    {
        id   : 'density',
        title: 'Density Register',
        type : 'basic',
        icon : 'note_add',
        link : '/dealer-density-register'
    },
    // {
    //     id   : 'preset',
    //     title: 'Pump Operations',
    //     type : 'basic',
    //     icon : 'double_arrow',
    //     link : '/dealer-preset-transaction'
    // },
    {
        id   : 'pricing',
        title: 'Pricing',
        type : 'basic',
        icon : 'price_check',
        link : '/dealer-pricing'
    },
    {
        id   : 'shifts',
        title: 'Shifts',
        type : 'basic',
        icon : 'access_time',
        link : '/dealer-shifts'
    },
    {
        id   : 'shiftlogs',
        title: 'Shift Reports',
        type : 'basic',
        icon : 'feather:file',
        link : '/dealer-shift-reports'
    },
    {
        id   : 'interlock',
        title: 'Interlock',
        type : 'basic',
        icon : 'heroicons_outline:lock-open',
        link : '/dealer-interlocks'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/dealer-users'
    },
    {
        id   : 'alert',
        title: 'Alerts',
        type : 'basic',
        icon : 'feather:alert-triangle',
        link : '/dealer-alerts'
    },
    {
        id   : 'audit',
        title: 'Audit Logs',
        type : 'basic',
        icon : 'feather:database',
        link : '/audit'
    },
    // {
    //     id: 'checklist',
    //     title: 'Checklist',
    //     type: 'basic',
    //     icon: 'heroicons_outline:building-office',
    //     link: '/checklist'
    // },
    // {
    //     id: 'assessment',
    //     title: 'Assessment',
    //     type: 'basic',
    //     icon: 'heroicons_outline:chart-pie',
    //     link: '/assessment'
    // },
    // {
    //     id: 'assessments',
    //     title: 'Assessments',
    //     type: 'basic',
    //     icon: 'heroicons_outline:chart-pie',
    //     link: '/assessments'
    // },
]
export const defaultNavigation: FuseNavigationItem[] = data;
export const compactNavigation: FuseNavigationItem[] = data;
export const futuristicNavigation: FuseNavigationItem[] = data;
export const horizontalNavigation: FuseNavigationItem[] = data;
